"use client";

import React from "react";
import { Icon } from "@iconify/react";
import Link from "next/link";
import {
  Collections,
  CollectionData,
} from "@/(global)/collections/data/collections";
import { ServicesContent } from "@/(global)/prestations/data/prestations";
import { type ServiceSection } from "@/(global)/prestations/data/types";

const latestCollections = Collections.slice(0, 3).map(
  (collection: CollectionData) => ({
    name: collection.title,
    href: `/collections/${collection.id}`,
  }),
);

const servicesLinks = Object.values(ServicesContent)
  .slice(0, 3)
  .map((service: ServiceSection) => ({
    name: service.bannerTitle,
    href: `/prestations/${service.id}`,
  }));

export const Footer = () => {
  const salonLinks = [
    { name: "Contact", href: "/contact" },
    { name: "Le salon", href: "/le-salon" },
    { name: "Blog", href: "/blog" },
  ];

  const socialLinks = [
    {
      name: "Instagram",
      icon: "formkit:instagram",
      href: "https://www.instagram.com/salonsalexandragrey/",
    },
    {
      name: "Facebook",
      icon: "formkit:facebook",
      href: "https://www.facebook.com/SalonsAlexandraGrey",
    },
    {
      name: "Linkedin",
      icon: "formkit:linkedin",
      href: "https://www.linkedin.com/company/alexandra-grey",
    },
  ];

  return (
    <footer className="bg-primary px-4 py-12 text-white ph:px-24 md:px-48 lg:px-72 xl:px-96">
      <div className="flex flex-col justify-between gap-8 xs:gap-16 lg:flex-row">
        {/* Brand Section */}
        <div className="lg:w-1/4">
          <div className="space-y-6 xs:space-y-8">
            <h2 className="font-cormorant text-2xl xs:text-3xl">AG</h2>
            <div className="flex gap-4 xs:gap-6">
              {socialLinks.map((social) => (
                <Link
                  key={social.name}
                  href={social.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="rounded-full bg-white p-2 transition-colors hover:bg-gray-200 xs:p-2.5"
                >
                  <Icon
                    icon={social.icon}
                    className="h-5 w-5 text-primary xs:h-6 xs:w-6"
                    aria-label={social.name}
                  />
                </Link>
              ))}
            </div>
          </div>
        </div>

        {/* Navigation Section */}
        <div className="lg:w-2/3">
          <div className="grid grid-cols-1 gap-8 xs:gap-12 ph:grid-cols-2 md:grid-cols-3 md:gap-8">
            {/* Services Column */}
            <div className="space-y-6">
              <h3 className="text-lg">
                <Link href="/prestations">PRESTATIONS</Link>
              </h3>
              <ul className="space-y-4">
                {servicesLinks.map((link) => (
                  <li key={link.name}>
                    <Link
                      href={link.href}
                      className="text-[#C5C5C3] transition-colors hover:text-gray-300"
                    >
                      {link.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            {/* Collections Column */}
            <div className="space-y-6">
              <h3 className="text-lg">
                <Link href="/collections">COLLECTIONS</Link>
              </h3>
              <ul className="space-y-4">
                {latestCollections.map((link) => (
                  <li key={link.name}>
                    <Link
                      href={link.href}
                      className="text-[#C5C5C3] transition-colors hover:text-gray-300"
                    >
                      {link.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            {/* Salon Column */}
            <div className="space-y-6 ph:col-span-2 md:col-span-1">
              <h3 className="text-lg">
                <Link href="/le-salon">LE SALON</Link>
              </h3>
              <ul className="space-y-4">
                {salonLinks.map((link) => (
                  <li key={link.name}>
                    <Link
                      href={link.href}
                      className="text-[#C5C5C3] transition-colors hover:text-gray-300"
                    >
                      {link.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            <div className="space-y-6">
              <h3 className="uppercase">Nos Salons</h3>
              <ul className="space-y-4 text-sm">
                <li>
                  <Link
                    href="/le-salon/villejuif"
                    className="text-[#C5C5C3] transition-colors hover:text-gray-300"
                  >
                    Salon de Coiffure Villejuif
                  </Link>
                </li>
                <li>
                  <Link
                    href="/le-salon/ville-davray"
                    className="text-[#C5C5C3] transition-colors hover:text-gray-300"
                  >
                    Salon de Coiffure Ville d&apos;Avray
                  </Link>
                </li>
                <li>
                  <Link
                    href="/le-salon/choisy-le-roi"
                    className="text-[#C5C5C3] transition-colors hover:text-gray-300"
                  >
                    Salon de Coiffure Choisy-le-Roi
                  </Link>
                </li>
                <li>
                  <Link
                    href="/le-salon/au-grey-des-couleurs"
                    className="text-[#C5C5C3] transition-colors hover:text-gray-300"
                  >
                    Au Grey des Couleurs Villejuif
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Bottom Section */}
      <div className="mt-12 flex flex-col items-center justify-between gap-6 border-t border-zinc-800 pt-6 xs:mt-24 xs:pt-8 md:flex-row">
        <div>
          <p className="text-center text-xs text-gray-400 xs:text-left xs:text-sm md:order-1">
            © {new Date().getFullYear()}{" "}
            <span className="text-white">Alexandra Grey.</span> Tous droits
            réservés.
          </p>

          <p className="text-center text-xs text-gray-400 xs:text-sm md:text-left">
            Site développé par{" "}
            <Link
              href="https://www.virtuosa.fr/"
              target="_blank"
              rel="noopener noreferrer"
              className="transition-colors hover:text-gray-300"
            >
              <span className="text-white">Virtuosa.</span>
            </Link>
          </p>
        </div>

        <div className="flex flex-col items-center gap-4 text-xs text-white xs:flex-row xs:gap-8 xs:text-sm md:order-2">
          <Link
            href="/dashboard"
            className="transition-colors hover:text-gray-300"
          >
            Gestion du site
          </Link>
          <Link
            href="/mentions-legales"
            className="transition-colors hover:text-gray-300"
          >
            Mentions légales
          </Link>
          <Link
            href="/cookies"
            className="transition-colors hover:text-gray-300"
          >
            Gestion des cookies
          </Link>
        </div>
      </div>
    </footer>
  );
};
