import(/* webpackMode: "eager", webpackExports: ["Canonical"] */ "/vercel/path0/frontend/app/components/Canonical.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/app/components/layout/Footer.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/app/components/layout/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/vercel/path0/frontend/app/providers.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/app/styles/_index.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
